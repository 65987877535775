<template>
    <div style="text-align: center; margin-top: 30px">
        <button class="tsr-btn tsr-size--default primary" @click="openPicker">
            <span>
                <span>Open</span>
            </span>
        </button>

        <picker v-if="isShowPicker" :title="picker.title" :items="picker.items" :activeValue="picker.activeValue"></picker>

<!--        <select-custom></select-custom>-->
    </div>
</template>

<script>
    import Picker from './../components/modules/Picker'
    // import SelectCustom from 'select-vue-custom'

    export default {
        components: {
            Picker,
            // SelectCustom
        },
        comments: {
            Picker,
        },
        data() {
            return {
                isShowPicker: false,
                picker: {
                    title: 'Picker',
                    items: {
                        0: {
                            value: '1',
                            name: '1kg',
                        }
                    },
                    activeValue: 5,
                },
            }
        },
        methods: {
            createItems() {
                for (let i=0; i <= 20; i++) {
                    this.picker.items[i] = {
                        value: i,
                        name: i + ' kg',
                    }
                }
            },
            openPicker() {
                this.isShowPicker = true;
            },
            closePicker() {
                this.isShowPicker = false;
            },
        },
        mounted() {
            this.createItems();
        }
    }
</script>

<template>
    <div class="r-menu-top__wrapper">
        <div class="r-menu-top-inner__wrapper">
            <div @click="toggleMenu" class="btn-burger">toggle btn</div>

            <div>info</div>
        </div>
    </div>
</template>

<script>

    export default {
        // mounted() {
        //
        // },
        methods: {
            toggleMenu() {
              const menu = document.getElementById('app');

                console.log(menu.classList.contains('menu-active'));

                if (menu.classList.contains('menu-active')) {
                    document.getElementById('app').classList.remove('menu-active');
                } else {
                    document.getElementById('app').classList.add('menu-active');
                }


            }
        }
    }
</script>

<template>
    <div>
        <top-menu></top-menu>

        <side-menu></side-menu>
    </div>
</template>

<script>
    import TopMenu from './../components/menu/TopMenu.vue'
    import SideMenu from './../components/menu/SideMenu.vue'

    export default {
        components: {
            TopMenu,
            SideMenu,
        }
    }

</script>

import 'normalize.css';
import '@/assets/css/base.css';

import '@/assets/css/components/btn.css';
import '@/assets/css/components/top-menu.css';
import '@/assets/css/components/side-menu.css';

import 'reflect-metadata';
import { createApp } from 'vue';

import { sync } from 'vuex-router-sync';
import App from './App.vue';
import router from './router/index';
import store from './store';

const app = createApp(App);

sync(store, router);

app.use(store);
app.use(router);
app.mount('#app')

import { RouteRecordRaw } from 'vue-router';
import {createRouter , createWebHistory} from 'vue-router';

import HomePage from '../pages/HomePage.vue';
import Dashboard from '../pages/Dashboard.vue';
import ProjectPage from '../pages/Project.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'home',
        component: () => import('../pages/HomePage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/',
        name: 'home',
        component: HomePage
    },
    {
        path: '/dashboard/',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/projects/',
        name: 'projects',
        component: Dashboard,
    },
    {
        path: '/project/picker',
        name: 'project',
        component: ProjectPage,
    }
    // {
    //     path: '*',
    //     name: 'not_found',
    //     meta: {
    //         requiresAuth: true,
    //     },
    //     component: () => import('../views/PageNotFound.vue'),
    // },
    // {
    //     path: '/login',
    //     name: 'login',
    //     meta: {
    //         requiresAuth: false,
    //     },
    //     component: () => import('../views/login/Index.vue'),
    // },
] as RouteRecordRaw[];

const routerHistory = createWebHistory();
const router = createRouter({
    history: routerHistory,
    routes,
})

export default router;

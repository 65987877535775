<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
})
export default class App extends Vue {

}
</script>

<style module>
  @import url('https://fonts.googleapis.com/css?family=Unica+One');

  html, body {
    font-family: 'Unica One', cursive;
    font-size: 16px;
  }

</style>

<template>
    <main class="r-main__wrapper">
        <div>the same</div>
        <a href="/" id="logo"></a>
    </main>
</template>

<script>

    export default {
        mounted() {
          const logo = document.getElementById('logo');

            setTimeout( function() {
                const logoTitle = 'RANDOM';
                let logoRandom = '';
                const logoTitleContainer = logo;
                const possible = "-+*/|}{[]~\\\":;?/.><=+-_)(*&^%$#@!)}";

                function generateRandomTitle(i, logoRandom) {
                    setTimeout( function() {
                        logoTitleContainer.innerHTML = logoRandom;
                    }, i*90 );
                }

                for( let i=0; i < logoTitle.length+1; i++ ) {
                    logoRandom = logoTitle.substr(0, i);
                    for (let j = i; j < logoTitle.length; j++) {
                        logoRandom += possible.charAt(Math.floor(Math.random() * possible.length));
                    }
                    generateRandomTitle(i, logoRandom);
                    logoRandom = '';
                }
            }, 500);
        }
    }

</script>

<style>
.r-main__wrapper {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 4em;
  height: 160px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #05070A;
  /*color: #1A1E23;*/
  /*color: #272C3A;*/
}

#logo {
  text-decoration: none;
  letter-spacing: 9px;
  font-weight: bold;
  color: #8a50cc;
}

#logo:hover {
  color: #ac63ff;
}
</style>

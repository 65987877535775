<template>
    <transition name="modal">
        <div class="tsr-modal-black__wrapper">
            <div class="tsr-modal__overlay">
                <table>
                    <tbody>
                    <tr>
                        <td>
                            <div class="tsr-modal__dark" @click="close"></div>

                            <div class="tsr-modal__wrapper">
                                <div class="tsr-modal-entry__wrapper">
                                    <div class="tsr-modal-title__wrapper">
                                        <div class="tsr-modal__close"></div>

                                        <div class="tsr-modal-title">{{ title }}</div>
                                    </div>

                                    <div class="tsr-modal-list__wrapper">
                                        <div class="tsr-modal-list-active"></div>

                                        <div class="tsr-modal-list">
                                            <span class="tsr-modal-list-item" :class="{'active': parseInt(index) === parseInt(activeValue)}"
                                                  v-for="(item, index) in items" :data-value="item.value" :key="`index_${index}`">{{ item.name }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </transition>
</template>

<script>

    export default {
        props: [
            'title',
            'items',
            'activeValue'
        ],
        data() {
            return {

            }
        },
        methods: {
            close() {
              console.log('close emit')
                // this.$parent.closePicker();
            }
        }
    }
</script>

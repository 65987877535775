<template>
    <div class="r-menu-side__wrapper">
        <div class="r-menu-side-inner__wrapper">
            <div>logo</div>

            <nav>
                <span><a href="#">Test</a></span>
                <span><a href="#">Home</a></span>
                <span><a href="#">Projects</a></span>
                <span><a href="#">Contacts</a></span>
            </nav>
        </div>
    </div>
</template>

<script>

</script>
